const loadStateStandards = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { subdivision } = state.user.user;

        const res = await api.standards.getStandardsByState({
            state: subdivision,
        });

        if (res.ok) {
            dispatch(actions.standards.setStateStandardsByState({
                stateName: subdivision,
                standards: res.standards,
            }));
        }
    };
};

const loadStandardsByGroups = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.standards.setStandardsByGroupsLoading());

        const res = await api.standards.getStandardsByGroups({
            session: state.user.session || "",
            state: state.user.user.subdivision,
        });

        let error = "";
        let data = null;

        if (res.ok) {
            data = res.standards || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.standards.setStandardsByGroups({
            data,
            error,
        }));
    };
};

export default {
    loadStateStandards,
    loadStandardsByGroups,
};

import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { useSelector } from "react-redux";

import array from "@/base/lib/array.js";
import date from "@/base/lib/date.js";
import time from "@/base/lib/time.js";
import device from "@/base/lib/device.js";
import fivesyStorage from "@/base/lib/storage/fivesy.js";

import User from "@/base/project/user.js";
import Grades from "@/base/project/grades.js";
import TeacherClasses from "@/base/project/teacher-classes.js";

import AudioManagerContext from "@/base/context/audio-manager/index.js";

import PopupFivesy from "@/base/components/popup-fivesy/index.js";

import app from "@/app/app.js";


const storage = fivesyStorage.getFivesyStorage();

const storeSelector = (state) => ({
    siteDate: state.info.siteDate,
    locationPathname: state.navigation.location?.pathname || "",

    session: state.user.session,
    userId: state.user.user.userId,
    user: state.user.user,

    teacherClassesLoaded: state.teacher.isClassesLoaded,
    teacherClasses: state.teacher.classes,

    wordsById: state.vocabulary.wordsById,
    playerState: state.player.playerState,

    fivesyStats: state.fivesy.stats,
    fivesyStudentAttempts: state.fivesy.attempts,
});

const PopupFivesyContainer = (props) => {
    const contentRef = useRef(null);

    const audioManager = useContext(AudioManagerContext);

    const [secretWord, setSecretWord] = useState({
        isLoaded: false,
        date: "",
        words: [],
        error: "",
    });

    const [isShowHowTo, setIsShowHowTo] = useState(false);
    const [isShowResult, setIsShowResult] = useState(false);

    // TODO: one hook
    const [timeStart, setTimeStart] = useState(0);
    const [selectedGrade, setSelectedGrade] = useState("");
    const [selectedWord, setSelectedWord] = useState(null);

    const [currentWord, setCurrentWord] = useState("");

    const [isTeacherPrevWordsLoaded, setIsTeacherPrevWordsLoaded] = useState(false);
    const [prevWords, setPrevWords] = useState({});

    const [isChecking, setIsChecking] = useState(false);
    const [isFlipping, setIsFlipping] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState("");
    // TODO: end one hook

    const store = useSelector(storeSelector);

    const isStudent = User.hasRoleStudent(store.user);
    const isTeacher = User.hasRoleTeacher(store.user);
    const isDistrictAdmin = User.hasRoleDistrictAdmin(store.user);

    /* --- */

    const showError = (msg) => {
        setError(msg);

        const secondsInMS = 3 * 1000;

        globalThis.setTimeout(() => {
            setError("");
        }, secondsInMS);
    };

    const showFlipping = () => {
        setIsFlipping(true);

        const secondsInMS = 2 * 1000;

        globalThis.setTimeout(() => {
            setIsFlipping(false);
        }, secondsInMS);
    };

    /* --- */

    const getIsLoading = () => {
        const isLoading = !store.siteDate
            || !secretWord.isLoaded;

        if (isStudent) {
            return isLoading
                || !store.fivesyStudentAttempts.isLoaded
                || store.fivesyStudentAttempts.isLoading;
        }

        return isLoading
            || !isTeacherPrevWordsLoaded
            || !store.teacherClassesLoaded;
    };

    const getWordsGradesOptions = () => {
        const ws = secretWord?.words || [];

        const allGrades = [];

        for (let i = 0; i < ws.length; i += 1) {
            const word = ws[i];
            allGrades.push(...word.grades);
        }

        if (Grades.isGradesGT(store.user.grades)) {
            return Grades.getGradesOptionsByGradesWithGT(allGrades);
        }

        return Grades.getGradesOptionsByGrades(allGrades);
    };

    const getWordByGrade = (words, grade) => {
        for (let i = 0; i < words.length; i += 1) {
            const word = words[i];

            if (Grades.isRangeInGrades(grade, word.grades)) {
                return word;
            }
        }

        if (!isTeacher && !isDistrictAdmin && words.length > 0) {
            return words[0];
        }

        return null;
    };

    const getTeacherPrevWords = (wordsDate) => {
        const gameState = storage.loadState();

        let allLoadedWords = {};

        if (gameState.date === wordsDate
            && gameState.userId === store.userId) {
            allLoadedWords = gameState.words || {};
        }

        return allLoadedWords;
    };

    /* --- */

    const loadGameHowTo = () => {
        const isHowTo = storage.loadHowTo();

        if (!isHowTo) {
            setIsShowHowTo(true);
            storage.saveHowTo();
        }
    };

    const loadGameTime = (wordsDate) => {
        const gameTime = storage.loadTime();

        if (!gameTime
            || gameTime?.date !== wordsDate
            || gameTime?.userId !== store.user.id) {
            storage.saveTime({
                userId: store.userId,
                date: wordsDate || "",
                timeTook: 0,
            });
        }

        const now = new Date().getTime();
        setTimeStart(now);
    };

    const loadTimeTook = () => {
        const timeTook = time.getTimeDiffFromNow(timeStart);

        const gameTime = storage.loadTime();
        const oldTimeTook = gameTime?.timeTook || 0;

        return oldTimeTook + timeTook;
    };

    const saveGameStart = () => {
        const timeTook = time.getTimeDiffFromNow(timeStart);

        const gameTime = storage.loadTime();
        const oldTimeTook = gameTime?.timeTook || 0;

        storage.saveTime({
            ...gameTime,
            timeTook: oldTimeTook + timeTook,
        });
    };

    const loadSecretWord = async () => {
        const res = await app.actions.common.fivesy.loadWord();
        const { words } = res;

        const wordsDate = words[0]?.date || "";

        setSecretWord((prev) => ({
            ...prev,
            isLoaded: true,
            date: wordsDate,
            words,
            error: res.error,
        }));

        for (let i = 0; i < words.length; i += 1) {
            const w = words[i];

            app.actions.common.vocabulary.loadWord({
                wordId: w.vocabularyId,
            });
        }
    };

    const loadStats = useCallback(async () => {
        app.actions.common.fivesy.loadUserStats();
    }, []);

    /* --- */

    const updateTeacherResult = (allWords, grade) => {
        const currWord = getWordByGrade(secretWord.words, grade);

        if (!currWord) {
            return;
        }

        app.services.events.fivesy.open({
            session: store.session,
            gameId: currWord?.id || "",
            location: store.locationPathname || "",
        });

        setSelectedWord(currWord || null);

        const words = allWords[currWord.id] || [];
        const lastWord = words[words.length - 1] || "";

        const isLimitReached = words.length === props.maxNumberOfAttempts;

        setIsFinished(isLimitReached || lastWord === currWord.word);
        setIsSuccess(lastWord === currWord.word);
    };

    const updateStudentResult = (word, loadedWords) => {
        setSelectedWord(word || null);

        app.services.events.fivesy.open({
            session: store.session,
            gameId: word?.id || "",
            location: store.locationPathname || "",
        });

        const lastWord = loadedWords[loadedWords.length - 1] || "";

        const isLimitReached = loadedWords.length === props.maxNumberOfAttempts;

        setIsFinished(isLimitReached || lastWord === word.word);
        setIsSuccess(lastWord === word.word);
    };

    const prepareStudentGame = async () => {
        const allLoadedWords = store.fivesyStudentAttempts.data || [];

        const sWord = secretWord.words[0] || null;
        const sWordId = sWord?.id || null;
        const sWordDate = sWord?.date || "";

        loadGameTime(sWordDate);

        if (sWordId) {
            setPrevWords((prev) => ({
                ...prev,
                [sWordId]: [...allLoadedWords],
            }));

            updateStudentResult(sWord, allLoadedWords);
        }
    };

    const prepareTeacherGame = () => {
        const sWord = secretWord.words[0] || null;
        const sWordId = sWord?.id || null;
        const sWordDate = sWord?.date || "";

        const allPreWords = getTeacherPrevWords(sWordDate);

        if (sWordId) {
            setPrevWords(allPreWords);
            setIsTeacherPrevWordsLoaded(true);

            updateTeacherResult(allPreWords, selectedGrade);
        }
    };

    /* --- */

    const onGradeChange = (value) => {
        setCurrentWord("");
        setSelectedGrade(value);

        updateTeacherResult(prevWords, value);
    };

    const onLetterChange = (value) => {
        setCurrentWord(value.word);
    };

    const onSaveTeacherGuess = (wordId, word) => {
        const gameState = storage.loadState();

        let allWords = {};

        if (gameState.date === secretWord.date
            && gameState.userId === store.userId) {
            allWords = gameState.words || {};
        }

        const words = allWords[wordId] || [];

        const newWords = {
            ...allWords,
            [wordId]: [
                ...words,
                word,
            ],
        };

        storage.saveState({
            userId: store.userId,
            date: secretWord.date,
            words: newWords,
        });
    };

    const onGuess = async (value) => {
        setIsChecking(true);
        setError("");

        const res = await app.actions.common.fivesy.guessWord({
            gameId: selectedWord?.id || "",
            location: store.locationPathname || "",
            word: value.word || "",
        });

        if (res.error) {
            setIsChecking(false);
            showError(res.error);
            return;
        }

        const currentSecretWord = array.findOneById(secretWord.words, value.wordId);
        const win = currentSecretWord?.word === value.word;

        const words = prevWords?.[value.wordId] || [];
        const numberOfTries = words.length + 1;

        const finished = numberOfTries === props.maxNumberOfAttempts
            || win;

        if (finished) {
            // NOTE: important to await event before fetching stats
            await app.services.events.fivesy.play({
                session: store.session,
                gameId: currentSecretWord?.id || "",
                location: store.locationPathname || "",
                guess: value.word || "",
                numberOfTries,
                timeTook: loadTimeTook(),
                win,
            });
        }

        setIsChecking(false);
        setCurrentWord("");
        showFlipping();

        if (finished) {
            setIsFinished(true);
            setIsSuccess(win);
            setIsShowResult(true);
        }

        if (isTeacher || isDistrictAdmin) {
            onSaveTeacherGuess(value.wordId, value.word);
        }

        setPrevWords((prev) => {
            const ws = prev?.[value.wordId] || [];

            return {
                ...prev,
                [value.wordId]: [
                    ...ws,
                    value.word,
                ],
            };
        });
    };

    const onClose = () => {
        app.services.events.fivesy.close({
            session: store.session,
            gameId: selectedWord?.id || "",
            location: store.locationPathname || "",
        });

        if (!isFinished) {
            saveGameStart();
        }

        props.onClose();
    };

    /* --- */

    useEffect(() => {
        loadGameHowTo();

        app.actions.common.site.loadSiteDate();

        if (isStudent) {
            app.actions.common.fivesy.loadUserAttempts();
        }

        loadSecretWord();
    }, []);

    useEffect(() => {
        if (isDistrictAdmin) {
            const defaultGrade = Grades.getMaxGradeValue();

            setSelectedGrade(defaultGrade);
            return;
        }

        if (!store.teacherClassesLoaded) {
            if (isTeacher) {
                app.actions.teacher.classes.loadAllClasses();
            }
            return;
        }

        const grade = TeacherClasses.getClassesDefaultGrade(store.teacherClasses);
        const gradeRange = Grades.getGradesRangeByGrade(grade);

        // NOTE: it can be loaded before or after game word
        setSelectedGrade(gradeRange);
    }, [store.teacherClassesLoaded]);

    useEffect(() => {
        if (!secretWord.isLoaded) {
            return;
        }

        if (contentRef?.current) {
            const maxHeight = contentRef?.current?.scrollHeight || 10000;
            contentRef.current.scrollTo(0, maxHeight);
        }
    }, [secretWord.isLoaded]);

    useEffect(() => {
        if (!(isTeacher || isDistrictAdmin)
            || !secretWord.isLoaded
            || !selectedGrade
            || selectedWord) {
            return;
        }

        prepareTeacherGame();
    }, [
        isTeacher,
        isDistrictAdmin,
        secretWord.isLoaded,
        selectedGrade,
    ]);

    useEffect(() => {
        if (!isStudent
            || !secretWord.isLoaded
            || !store.fivesyStudentAttempts.isLoaded
            || store.fivesyStudentAttempts.isLoading) {
            return;
        }

        prepareStudentGame();
    }, [
        isStudent,
        store.fivesyStudentAttempts.isLoaded,
        store.fivesyStudentAttempts.isLoading,
        secretWord.isLoaded,
    ]);

    /* --- */

    const isLoading = getIsLoading();

    const wordPrevWords = prevWords?.[selectedWord?.id] || [];
    const vocabularyWord = store.wordsById?.[selectedWord?.vocabularyId] || null;

    const siteDate = date.newDate(store.siteDate) || new Date();
    const nextDay = date.getNextDay(siteDate);
    const nextDate = date.getDateNowWithOffset(siteDate, nextDay);

    const stats = {
        data: store.fivesyStats?.data || null,
        error: store.fivesyStats?.error || "",
        onLoad: loadStats,
        isVisible: isStudent,
        isLoading: !store.fivesyStats?.isLoaded
            || store.fivesyStats?.isLoading,
    };

    const isResultsButtonDisabled = !(isStudent || isFinished);

    return (
        <PopupFivesy
            contentRef={contentRef}
            selectedGrade={selectedGrade}
            grades={getWordsGradesOptions()}
            word={selectedWord}
            currentWord={currentWord}
            maxNumberOfAttempts={props.maxNumberOfAttempts}
            words={secretWord.words}
            prevWords={wordPrevWords}
            nextDate={nextDate}
            vocabularyWord={vocabularyWord}
            audio={audioManager}
            audioState={store.playerState}
            wordError={secretWord.error}
            error={error}
            stats={stats}
            onGradeChange={onGradeChange}
            onLetterChange={onLetterChange}
            onGuess={onGuess}
            onClose={onClose}
            isChecking={isChecking}
            isFlipping={isFlipping}
            isFinished={isFinished}
            isSuccess={isSuccess}
            isLoading={isLoading}
            isResultsButtonDisabled={isResultsButtonDisabled}
            isSafari={device.isSafari}
            showInfo={isShowHowTo}
            showResult={isShowResult}
        />
    );
};

PopupFivesyContainer.defaultProps = {
    maxNumberOfAttempts: 6,
    onClose: () => { },
};

export default PopupFivesyContainer;

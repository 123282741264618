import { useState } from "react";


const getState = () => ({
    isChecked: false,
    selectedValue: "",
    selectedName: "",
});

const useToggleDropdown = () => {
    const [state, setState] = useState(() => getState());

    /* ---- */

    const onChange = (values) => {
        setState((prev) => ({
            ...prev,
            ...values,
        }));
    };

    /* ---- */

    return {
        state,
        onChange,
    };
};

export default useToggleDropdown;
